import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '~/components/layout'
import SEOEn from '~/components/seoEn'
import PaymentFormEn from '~/components/paymentFormEn'

const PaymentPageEn = ({ data: { paiementEn, paiementFr } }) => {
  const { title, title_caution, graph, graph_caution } = paiementEn.nodes[0]
  const { background } = paiementFr.nodes[0]
  const seo = {
    title: 'Payment',
    metaDescription:
      "Pay your invoices following your purchase in the auction room. We're using Banque Populaire for secured and fast transactions.",
  }

  const [option, setOption] = useState('invoice')

  return (
    <Layout isEn isAbsolute={false}>
      <Helmet>
        <script
          type="text/javascript"
          src="https://static.systempay.fr/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js"
          kr-public-key="73239078:testpublickey_Zr3fXIKKx0mLY9YNBQEan42ano2QsdrLuyb2W54QWmUJQ"
          kr-placeholder-pan="XXXX XXXX XXXX 1234"
          kr-placeholder-expiry="ex : 01/29"
          kr-placeholder-security-code="XXX"
          kr-post-url-success="https://dufreche.fr/success"
        />
        <link
          rel="stylesheet"
          href="https://static.systempay.fr/static/js/krypton-client/V4.0/ext/neon-reset.min.css"
        />
        <script
          type="text/javascript"
          src="https://static.systempay.fr/static/js/krypton-client/V4.0/ext/neon.js"
        ></script>
      </Helmet>
      <SEOEn seo={seo} />
      <div
        className="bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${background.url})` }}
      >
        <div className="grid grid-cols-12 container">
          <div className="col-start-1 col-end-13 min-h-screen laptop:col-start-4 laptop:col-end-10">
            <div className="mt-5 mb-8 pt-6 pb-2 bg-gray-100">
              <div className="px-3 text-center">
                <div className="largeMobile:flex largeMobile:w-max mx-auto mt-4 mb-6 p-1 bg-white">
                  <div
                    onClick={() => setOption('invoice')}
                    className={`${
                      option === 'invoice' ? 'bg-gray-100' : 'bg-white'
                    } p-2 cursor-pointer`}
                  >
                    <label
                      className={`${
                        option === 'invoice' ? 'font-bold' : ''
                      } uppercase cursor-pointer tracking-wider`}
                      htmlFor="all"
                    >
                      Invoice payment
                    </label>
                    <input id="invoice" className="hidden" type="radio" />
                  </div>
                  <div
                    onClick={() => setOption('caution')}
                    className={`${
                      option === 'caution' ? 'bg-gray-100' : 'bg-white'
                    } p-2 cursor-pointer`}
                  >
                    <label
                      className={`${
                        option === 'caution' ? 'font-bold' : ''
                      } uppercase cursor-pointer tracking-wider`}
                      htmlFor="besancon"
                    >
                      Caution
                    </label>
                    <input id="caution" className="hidden" type="radio" />
                  </div>
                </div>
                {option == 'invoice' && (
                  <>
                    <h3 className="mb-4 font-bold uppercase tracking-wide">
                      {title}
                    </h3>
                    <ReactMarkdown className="text-left max-w-500 mx-auto pb-5 mb-2 border-b border-gray-400 tablet:mb-0">
                      {graph}
                    </ReactMarkdown>
                  </>
                )}
                {option == 'caution' && (
                  <>
                    <h3 className="mb-4 font-bold uppercase tracking-wide">
                      {title_caution}
                    </h3>
                    <ReactMarkdown className="text-left max-w-500 mx-auto pb-5 mb-2 border-b border-gray-400 tablet:mb-0">
                      {graph_caution}
                    </ReactMarkdown>
                  </>
                )}
              </div>
              <div className="col-start-1 col-end-13">
                <PaymentFormEn option={option} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query paymentQueryAll {
    paiementEn: allStrapiPaiement(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        title_caution
        graph
        graph_caution
        background {
          url
        }
      }
    }
    paiementFr: allStrapiPaiement(filter: { locale: { eq: "fr-FR" } }) {
      nodes {
        title
        title_caution
        graph
        graph_caution
        background {
          url
        }
      }
    }
  }
`

export default PaymentPageEn
